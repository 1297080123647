<template>
  <div
    :class="`p-relative bg-image ratio ${src ? 'content-center' : ''}`"
    @click="$emit('click', $event)"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    :style="{
      backgroundImage: 'url(' + src + ')',
      paddingBottom: ratio ? padding + '%' : '',
      backgroundSize: contain ? 'contain' : 'cover',
      backgroundRepeat: 'no-repeat'
    }"
  >
    <div class="p-absolute fill-parent content-center" v-if="!src">
      <span class="sticker bg-light"><i class="i-video"></i></span>
    </div>

    <div
      v-if="!this.playback && src && card"
      class="p-absolute fill-parent content-center z-9 overlay-dark overlay-70"
      :style="{ left: 0, top: 0 }"
      @mouseenter="toggleOverlay"
      @mouseleave="toggleOverlay"
    >
      <span class="sticker bg-light"><i class="i-play"></i></span>
    </div>

    <video
      v-if="src && card"
      @loadeddata="dataLoaded"
      @mouseenter="startVideo"
      @mouseleave="stopVideo"
      loop
      muted
      :src="src"
      class="d-block"
      :style="{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        left: 0,
        top: 0
      }"
    >
      Тег video не поддерживается вашим браузером.
    </video>

    <video
      v-else-if="src && !card"
      @loadeddata="dataLoaded"
      loop
      controls
      :src="src"
      class="d-block"
    >
      Тег video не поддерживается вашим браузером.
    </video>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    ratio: String,
    contain: Boolean,
    card: Boolean
  },
  computed: {
    padding() {
      let r = this.ratio ? this.ratio.toString().split("x") : [4, 3];

      return (r[1] / r[0]) * 100;
    }
  },
  methods: {
    dataLoaded(event) {
      this.loaded = true;
      this.$emit("loadedVideo");

      if (!this.card) {
        const vid = event.target;
        if (vid.videoWidth > vid.videoHeight) {
          vid.style.width = "100%";
          vid.style.height = "100%";
        } else {
          vid.style.maxWidth = "100%";
          vid.style.maxHeight = "600px";
        }
      }

      this.stopVideo(event);
    },

    startVideo(event) {
      const vid = event.target;
      vid
        .play()
        .then(() => (this.playback = true))
        .catch(err => console.log("playback failure", err));
    },

    stopVideo(event) {
      const vid = event.target;
      vid.pause();
      vid.currentTime = 0;
      this.playback = false;
    },

    toggleOverlay(event) {
      const overlay = event.target;
      overlay.style.display = !this.playback ? "none" : "block";
    }
  },
  data() {
    return {
      loaded: false,
      playback: false
    };
  },
  watch: {
    src: function(newVal) {
      if (!newVal) return;

      this.src = newVal;

      this.loaded = false;
    }
  },
  mounted() {
    if (this.src === undefined) return;
  }
};
</script>
